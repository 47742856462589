import React from 'react';
import {
  Aireplane,
  ArrowContainer,
  Background,
  Headline,
  BlurCircle,
  ImgDesktop,
  Subtitle,
  Title,
  Content,
  ButtonContainer,
  Button,
} from './styles';
import AirplaneImg from '../../images/airplan.png';
import DownArrow from '../../images/down-arrow.png';
import { useSpring } from 'react-spring';

export const Slogan = ({ arrowFunc }) => {
  const styles = useSpring({
    loop: true,
    to: [{ opacity: 1 }, { opacity: 0 }],
    from: { opacity: 0 },
  });

  const fadeInLeft = useSpring({
    opacity: 1,
    delay: 500,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeIn = useSpring({
    opacity: 1,
    delay: 250,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  return (
    <Background>
      <div className="airplane airplane__red-two" />
      <Content>
        <Headline style={fadeInLeft}>
          <Title>
            ¡VIVE
            <br />
            UNA NUEVA EXPERIENCIA!
          </Title>
          <Subtitle>Everywhere, everytime</Subtitle>
        </Headline>
        <Aireplane>
          <BlurCircle style={fadeIn}>
            <div className="airplane airplane__yellow" />
            <div className="airplane airplane__blue" />
            <div className="airplane airplane__red-one" />
            <ImgDesktop src={AirplaneImg} alt="Airplane" />
          </BlurCircle>
        </Aireplane>
      </Content>
      <ButtonContainer>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/rastreo/', '_blank')}>
          Rastreo de carga
        </Button>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/', '_blank')}>Ingreso agencias</Button>
      </ButtonContainer>

      <ArrowContainer style={styles} onClick={arrowFunc}>
        <img src={DownArrow} alt="Down Arrow" />
      </ArrowContainer>
    </Background>
  );

  // return (
  //   <Background ref={handleRef}>
  //     <Aireplane>
  //       <ImgMobile src={AirplaneMobile} alt="Airplane Mobile" />
  //       <ImgDesktop src={AirplaneDesk} alt="Airplane Desktop" />
  //     </Aireplane>
  //     <Headline>
  //       <Title>¡VIVE UNA NUEVA EXPERIENCIA!</Title>
  //       <Subtitle>Everywhere, everytime</Subtitle>
  //     </Headline>

  //     <ArrowContainer style={styles} onClick={arrowFunc}>
  //       <Icon icon="icon-chevron-down" size="bg" />
  //     </ArrowContainer>
  //   </Background>
  // );
};
