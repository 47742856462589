import styled from '@emotion/styled';

const getSize = size =>
  ({
    xs: `1.4rem`,
    sm: `1.8rem`,
    md: `2.7rem`,
    bg: `4.3rem`,
  }[size ?? 'md']);

export const SvgWrapper = styled.svg`
  fill: currentColor;
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
`;
