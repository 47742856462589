import styled from '@emotion/styled';
import { a } from 'react-spring';
import DivisaBg from '../../images/divisabg.png';

export const Background = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: ${({ theme }) => theme.colors.dark};
  width: 100vw;
  min-height: 100vh;

  @media (min-width: 1280px) {
    padding: 0 0 0 96px;
    background-image: url(${DivisaBg});
    background-repeat: no-repeat;
    background-position: right;
    flex-direction: row-reverse;
    min-height: 76.8rem;
    justify-content: center;
  }
`;

export const BgImg = styled.img`
  position: absolute;
  z-index: 0;
  right: 0;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const BarImg = styled.img`
  position: absolute;
  z-index: 0;
  left: 0;
  @media (min-width: 1280px) {
  }
`;

export const MoneyImg = styled.img`
  position: absolute;
  z-index: 0;
  right: 0;
  top: 120px;
  @media (min-width: 1280px) {
    display: none;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  flex: 1;

  @media (min-width: 1280px) {
    height: auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 3%;
  }
`;

export const ChartImg = styled(a.img)`
  width: 65%;
  transform: translate(-10px, 0);
  margin-top: 150px;
  filter: drop-shadow(0px 3px 6px #00000029);

  @media (min-width: 1280px) {
    width: auto;
    height: 438px;
    transform: translate(-60%, 42%);
  }
`;

export const TextContainer = styled.div`
  padding: 0 38px;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    position: relative;
    margin-top: 200px;
    align-items: flex-start;
  }
`;

export const Title = styled(a.h1)`
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  font-family: 'Sofia Pro', sans-serif;
  line-height: 1;
  width: 270px;
  margin: auto;
  text-shadow: 0px 3px 6px #00000029;

  @media (min-width: 1280px) {
    margin: 0 auto;
    font-size: 70px;
    text-align: start;
    width: 450px;
  }
`;

export const Description = styled(a.p)`
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
  width: 245px;

  @media (min-width: 1280px) {
    margin: 1rem 0;
    text-align: start;
    width: 368px;
  }
`;

const ButtonColor = variant =>
  ({
    primary: ({ theme }) => theme.colors.primary,
    secondary: ({ theme }) => theme.colors.dark,
  }[variant ?? 'primary']);

const ButtonSize = size =>
  ({
    sm: '0.4rem 1.4rem',
    md: '1.2rem 2.4rem',
  }[size ?? 'md']);

export const CustomButton = styled(a.button)`
  padding: ${({ size }) => ButtonSize(size)};
  border: none;
  background-color: ${({ variant }) => ButtonColor(variant)};
  color: white;
  border-radius: 20rem;
  margin: auto;

  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }

  @media (min-width: 1280px) {
    margin: 0;
    min-width: 152px;
  }
`;

export const ButtonContainer = styled(a.div)`
  display: flex;
  flex-direction: column;
  margin: 0.65rem auto 1.25rem;

  @media (min-width: 1280px) {
    position: absolute;
    bottom: 75px;
    left: 140px;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;
