import React, { useState, useEffect } from 'react';
import { useSpring, useTrail, a } from 'react-spring';

import Mapa from '../../images/mapa-desk.svg';

import ColombiaFlag from '../../images/col-flag.svg';
import USAFlag from '../../images/usa-flag.svg';
import MexicoFlag from '../../images/mexico-flag.svg';
import EcuadorFlag from '../../images/ecuador-flag.svg';
import VenezuelaFlag from '../../images/venuezuela-flag.svg';
import DominicanaFlag from '../../images/dominicana-flag.svg';
import SalvadorFlag from '../../images/salvador-flag.svg';
import HondurasFlag from '../../images/honduras-flag.svg';

import GuatemalaFlag from '../../images/guatemala-flag.svg';

import LibertyStatueNy from '../../images/liberty-statue-ny.svg';
import FaroNewJersey from '../../images/faro-new-jersey.svg';
import LibertyBellPenns from '../../images/liberty-bell-penns.svg';

import Casita from '../../images/casita.svg';

import {
  FlagsStyle,
  CoberturaContainer,
  ColumnsCobertura,
  ColumnInfoCobertura,
  ColumnMapCobertura,
  MapaImagenStyle,
  TitleCobertura,
  ParrafoCobertura,
  ListadoPaises,
  TitleAgentes,
  ParrafoAgentes,
  ContentIconsCities,
  ContentIconText,
  NewYorkImage,
  TextCity,
  LindenOffice,
  BogotaOffice,
  CasitaStyleLinden,
  CasitaStyleBogota,
  Divider,
  CountryLabel,
  LabelContainer,
  AuthorizedContainer,
} from './styles';
import { isBrowser } from '../../utils';

export const Cobertura = ({ handleRef, position }) => {
  const COUNTRIES = [
    {
      flag: ColombiaFlag,
      alt: 'Mapa Colombia',
      label: 'Colombia',
      split: false,
    },
    {
      flag: SalvadorFlag,
      alt: 'Mapa Salvador',
      label: 'Salvador',
      split: false,
    },
    {
      flag: USAFlag,
      alt: 'Mapa Estados Unidos',
      label: 'USA',
      split: false,
    },
    {
      flag: EcuadorFlag,
      alt: 'Mapa Ecuador',
      label: 'Ecuador',
      split: false,
    },
    {
      flag: MexicoFlag,
      alt: 'Mapa México',
      label: 'México',
      split: false,
    },
    {
      flag: GuatemalaFlag,
      alt: 'Mapa Guatemala',
      label: 'Guatemala',
      split: false,
    },
    {
      flag: VenezuelaFlag,
      alt: 'Mapa Venezuela',
      label: 'Venezuela',
      split: false,
    },
    {
      flag: HondurasFlag,
      alt: 'Mapa Honduras',
      label: 'Honduras',
      split: false,
    },
    {
      flag: DominicanaFlag,
      alt: 'Mapa República Dominicana',
      label: 'República Dominicana',
      split: true,
      spliName: 'R.Dominicana',
    },
  ];

  const CITIES = [
    {
      img: LibertyStatueNy,
      alt: 'New York Icon',
      label: 'New York',
    },
    {
      img: FaroNewJersey,
      alt: 'New Jersey Icon',
      label: 'New Jersey',
    },
    {
      img: LibertyBellPenns,
      alt: 'Pennsylvania Icon',
      label: 'Pennsylvania',
    },
  ];

  const [width, setWidth] = useState(0);

  const fadeInTitle = useSpring({
    opacity: position > 2100 ? 1 : 0,
    transform: position > 2100 ? 'translate(0, 0)' : 'translate(-50px, 0)',
  });

  const fadeInDesc = useSpring({
    opacity: position > 2200 ? 1 : 0,
    transform: position > 2200 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 250,
  });

  const fadeInMap = useSpring({
    opacity: position > 2100 ? 1 : 0,
    delay: 175,
    transform: position > 2100 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const fadeInCardOne = useSpring({
    opacity: position > 2200 ? 1 : 0,
    delay: 400,
    transform: position > 2200 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const fadeInCardTwo = useSpring({
    opacity: position > 2300 ? 1 : 0,
    delay: 500,
    transform: position > 2300 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const trailContries = useTrail(COUNTRIES.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: position > 2300 ? 1 : 0,
    x: position > 2300 ? 0 : -20,
    from: { opacity: 0, x: -20 },
    delay: 450,
  });

  const fadeInDiv = useSpring({
    opacity: position > 2900 ? 1 : 0,
    delay: 175,
    transform: position > 2900 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const fadeInTitleTwo = useSpring({
    opacity: position > 2900 ? 1 : 0,
    transform: position > 2900 ? 'translate(0, 0)' : 'translate(-50px, 0)',
  });

  const fadeInDescTwo = useSpring({
    opacity: position > 2900 ? 1 : 0,
    transform: position > 2900 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 250,
  });

  const trailCities = useTrail(CITIES.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: position > 2900 ? 1 : 0,
    y: position > 2900 ? 0 : -20,
    from: { opacity: 0, y: -20 },
    delay: 350,
  });

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <CoberturaContainer ref={handleRef}>
      <ColumnsCobertura>
        <ColumnInfoCobertura>
          <TitleCobertura style={fadeInTitle}>
            Nuestra <span> Cobertura </span>
          </TitleCobertura>
          <ParrafoCobertura style={fadeInDesc}>
            Nuestra oficina principal se encuentra ubicada en New Jersey. Contamos con oficina en Colombia y aliados
            logísticos en México, Guatemala, Honduras, El Salvador, República Dominicana, Venezuela y Ecuador.
          </ParrafoCobertura>
          <ListadoPaises>
            {trailContries.map((animation, idx) => (
              <a.li style={animation}>
                <FlagsStyle src={COUNTRIES[idx].flag} alt={COUNTRIES[idx].alt} />
                <LabelContainer>
                  <CountryLabel
                    style={
                      (!!COUNTRIES[idx].split &&
                        (isBrowser && width < 1280 ? { fontSize: '9px' } : { fontSize: '14px' })) ||
                      {}
                    }
                  >
                    {COUNTRIES[idx].split
                      ? isBrowser && (width < 1280 ? COUNTRIES[idx].spliName : COUNTRIES[idx].label)
                      : COUNTRIES[idx].label}
                  </CountryLabel>
                </LabelContainer>
              </a.li>
            ))}
          </ListadoPaises>
        </ColumnInfoCobertura>
        <ColumnMapCobertura style={fadeInMap}>
          <LindenOffice style={fadeInCardOne}>
            <CasitaStyleLinden src={Casita} />
            <h3>Oficina Principal.</h3>
            <p>
              2430 E. Linden Ave, <br /> Linden, New Jersey <br /> 07036 <br /> USA
            </p>
          </LindenOffice>
          <BogotaOffice style={fadeInCardTwo}>
            <CasitaStyleBogota src={Casita} />
            <h3>Oficina Principal Bogotá.</h3>
            <p>
              Calle 23D # 101B - 63 <br /> Colombia
            </p>
          </BogotaOffice>
          <MapaImagenStyle src={Mapa} alt="Mapa y marcadores" />
        </ColumnMapCobertura>
      </ColumnsCobertura>
      <AuthorizedContainer>
        <Divider style={fadeInDiv} />

        <TitleAgentes style={fadeInTitleTwo}>
          Agentes <span>autorizados</span>
        </TitleAgentes>
        <ParrafoAgentes style={fadeInDescTwo}>
          Actualmente, contamos con agentes autorizados y especializados en los siguientes estados:
        </ParrafoAgentes>

        <ContentIconsCities>
          {trailCities.map((animation, idx) => (
            <ContentIconText style={animation}>
              <NewYorkImage src={CITIES[idx].img} alt={CITIES[idx].alt} />
              <TextCity> {CITIES[idx].label} </TextCity>
            </ContentIconText>
          ))}
          {/* <ContentIconText>
            <NewYorkImage src={LibertyStatueNy} alt="Liberty Statue Ny" />
            <TextCity> New York </TextCity>
          </ContentIconText>

          <ContentIconText>
            <NewYersyImage src={FaroNewJersey} alt="Liberty Statue Ny" />
            <TextCity> New Jersey </TextCity>
          </ContentIconText>

          <ContentIconText>
            <PennsylvaniaImage src={LibertyBellPenns} alt="Liberty Bell Penns" />
            <TextCity> Pennsylvania </TextCity>
          </ContentIconText> */}
        </ContentIconsCities>
      </AuthorizedContainer>
    </CoberturaContainer>
  );
};
