import React, { useState } from 'react';
import { useSpring, a } from 'react-spring';

import RegisterAgent from '../../images/RegistroAgentes.pdf';
import DocIcon from '../../images/DocIcon.svg';

import {
  Background,
  Content,
  ChartImg,
  TextContainer,
  Title,
  Description,
  CustomButton,
  ButtonContainer,
  BgImg,
  BarImg,
  MoneyImg,
} from './styles';

import Divisa from '../../images/divisa.png';
import DivisaBg from '../../images/divisaBg.svg';
import BarBg from '../../images/divisaBar.svg';
import DivisaMoney from '../../images/divisaMoney.svg';
import { Icon } from '../Icon';
import { Button, ButtonContainer as HiddenButton } from '../Slogan/styles';

export const Agent = () => {
  const [state, toggle] = useState(false);

  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 150 },
  });

  const fadeInLeftOne = useSpring({
    opacity: 1,
    delay: 500,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeInLeftTwo = useSpring({
    opacity: 1,
    delay: 600,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeInLeftThree = useSpring({
    opacity: 1,
    delay: 700,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeIn = useSpring({
    opacity: 1,
    delay: 250,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  return (
    <Background>
      <BgImg src={DivisaBg} alt="Divisa background" />
      <BarImg src={BarBg} alt="Bar background" />
      <MoneyImg src={DivisaMoney} alt="Money background" />
      <Content>
        <a.div style={fadeIn}>
          <ChartImg src={Divisa} alt="Divisa" />
        </a.div>

        <TextContainer>
          <Title style={fadeInLeftOne}>¿Cómo puedo ser un agente autorizado?</Title>

          <Description style={fadeInLeftTwo}>
            Si deseas aumentar tus ganancias, solo haz clic y descarga el formulario, diligencialo con la información
            solicitada y envíalo a nuestra oficina en New Jersey.
          </Description>

          <CustomButton
            onClick={() => toggle(!state)}
            style={{ width: '152px', cursor: 'pointer', ...fadeInLeftThree }}
          >
            Descargar <Icon size="xs" icon="icon-arrow-down" />
          </CustomButton>

          <ButtonContainer
            style={{
              opacity: x.to({ range: [0, 1], output: [0, 1] }),
              cursor: x ? 'pointer' : 'default',
            }}
          >
            <CustomButton size="sm" onClick={() => window.open(RegisterAgent, '_blank')} disabled={!state}>
              <img src={DocIcon} alt="doc icon" />
              Formulario Registro
            </CustomButton>
          </ButtonContainer>
        </TextContainer>
      </Content>

      <HiddenButton>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/rastreo/', '_blank')}>
          Rastreo de carga
        </Button>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/', '_blank')}>Ingreso agencias</Button>
      </HiddenButton>
    </Background>
  );
};
