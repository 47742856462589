import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const MainSection = styled.section`
  height: 812px;
  position: relative;

  @media (min-width: 1280px) {
    height: 768px;
  }
`;

export const ControllerContainer = styled(animated.div)`
  position: absolute;
  top: 241px;
  left: 0;
  color: white;
`;

export const ArrowContainer = styled.div`
  display: flex;

  div:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;

export const ArrowBox = styled.div`
  display: flex;
  backdrop-filter: blur(2px);
  cursor: pointer;
  background: transparent linear-gradient(89deg, #ffffff66 0%, #ffffff1a 100%) 0% 0% no-repeat padding-box;
  height: 38px;
  width: 38px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 1280px) {
    height: 48px;
    width: 48px;
  }
`;

export const ArrowImg = styled.img`
  margin: auto;
`;

export const SocialContainer = styled.div`
  width: 36px;
  padding: 26.5px 12.5px;
  background: transparent linear-gradient(90deg, #ffffff66 0%, #ffffff0d 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 8px 0px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgb(255 255 255 / 80%);

  @media (min-width: 1280px) {
    width: 48px;
  }
`;

export const HeightBar = styled.div`
  height: 179px;
  background-color: #fff;
  width: 2px;
  margin-bottom: 27px;
`;

export const SocialIconBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
