import React from 'react';
import { useSpring, useTrail, a } from 'react-spring';

import TSA from '../../images/TSA_Logo.png';
import CLDA from '../../images/CLDA_Logo.png';
import World from '../../images/about.png';
import {
  Bullet,
  PStyle,
  TwoColums,
  ColumAbout,
  ColumImage,
  MainContainer,
  CenterMainContainer,
  Title,
  UlStyle,
  ListItemStyle,
  ImgContainer,
  ImgStyle,
  LogosContainer,
  Section,
  Subtitle,
  Logos,
  Footer,
  ContentFooter,
} from './styles';

export const About = ({ handleRef, position }) => {
  const OPTS = [
    'Contamos con más de 100 puntos de agenciamiento en Estados unidos.',
    'Somos miembros de Customized Logistics & Delivery Association.',
    'Somos regulados en Estados Unidos por el TSA (Transportation - Security - Administration).',
  ];

  const fadeInLeft = useSpring({
    opacity: position > 516 ? 1 : 0,
    transform: position > 516 ? 'translate(0, 0)' : 'translate(-50px, 0)',
  });

  const fadeInLeftP = useSpring({
    opacity: position > 516 ? 1 : 0,
    transform: position > 516 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 500,
  });

  const fadeInRight = useSpring({
    opacity: position > 516 ? 1 : 0,
    transform: position > 516 ? 'translate(0, 0)' : 'translate(50px, 0)',
    delay: 250,
  });

  const fadeInOne = useSpring({
    opacity: position > 616 ? 1 : 0,
    delay: 350,
    transform: position > 616 ? 'scale(1)' : 'scale(2.3)',
    from: { opacity: 0, transform: 'scale(2.3)' },
  });

  const fadeInTwo = useSpring({
    opacity: position > 516 ? 1 : 0,
    delay: 450,
    transform: position > 516 ? 'scale(1)' : 'scale(2.6)',
    from: { opacity: 0, transform: 'scale(2.6)' },
  });

  const trail = useTrail(OPTS.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: position > 616 ? 1 : 0,
    x: position > 616 ? 0 : 20,
    from: { opacity: 0, x: 20 },
    delay: 600,
  });

  const fadeInFooter = useSpring({
    opacity: position > 800 ? 1 : 0,
    from: { opacity: 0 },
  });

  const animatedFooterTitle = useSpring({
    opacity: position > 800 ? 1 : 0,
    transform: position > 800 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 500,
  });

  const footerLogoContainer = useSpring({
    opacity: position > 800 ? 1 : 0,
    transform: position > 800 ? 'translate(0, 0)' : 'translate(0, -50px)',
    delay: 325,
  });

  return (
    <MainContainer ref={handleRef}>
      <CenterMainContainer>
        <Section>
          <TwoColums>
            <ColumAbout>
              <Title style={fadeInLeft}>
                Acerca <br />
                de nosotros.
              </Title>

              <PStyle style={fadeInLeftP}>
                Somos una compañía fundada hacia el año 2007 en el estado de New Jersey. Nuestra amplia experiencia en
                el mercado de transporte nos permite prestar el servicio aéreo, marítimo de carga y paquetería a
                diferentes países de Latinoamérica.
              </PStyle>

              <UlStyle>
                {trail.map((a, idx) => {
                  return <ListItem animation={a} key={idx} text={OPTS[idx]} numb={`0${idx + 1}`} />;
                })}
              </UlStyle>
            </ColumAbout>

            <ColumImage>
              <a.div style={fadeInTwo} className="service service__yellow" />
              <a.div style={fadeInOne} className="service service__blue" />
              <ImgContainer style={fadeInRight}>
                <ImgStyle src={World} alt="Nosotros Mundo" />
              </ImgContainer>
              <div className="shadow" />
            </ColumImage>
          </TwoColums>
        </Section>
      </CenterMainContainer>

      <Footer style={fadeInFooter}>
        <ContentFooter>
          <Subtitle style={animatedFooterTitle}>Avalados por:</Subtitle>
          <LogosContainer style={footerLogoContainer}>
            <Logos src={TSA} alt="TSA Logo" />
            <Logos src={CLDA} alt="CLDA Logo" />
          </LogosContainer>
        </ContentFooter>
      </Footer>
    </MainContainer>
  );
};

const ListItem = ({ text, numb, animation }) => (
  <ListItemStyle style={animation}>
    <Bullet>{numb}</Bullet> <PStyle>{text}</PStyle>
  </ListItemStyle>
);
