import React, { useEffect, useState } from 'react';
import { animated, useTransition, useSpring } from 'react-spring';

import { OurInfo } from '../OurInfo';
import { Slogan } from '../Slogan';
import { Agent } from '../Agent';

import { Icon } from '../Icon';
import Arrow from '../../images/fordware.svg';
import ArrowO from '../../images/fordware-o.svg';
import {
  ArrowBox,
  ArrowContainer,
  ControllerContainer,
  SocialContainer,
  HeightBar,
  SocialIconBox,
  MainSection,
  ArrowImg,
} from './styles';

export const Carousel = ({ handleRef, arrowFunc }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setPosition(idx => (idx + 1) % 3);
    }, 6000);
  }, []);

  const transitions = useTransition(position, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      duration: 500,
    },
  });

  const RenderSlide = idx =>
    ({
      0: <Slogan arrowFunc={arrowFunc} />,
      1: <OurInfo />,
      2: <Agent />,
    }[idx ?? 0]);

  return (
    <MainSection ref={handleRef}>
      {transitions((style, idx) => (
        <animated.div style={{ ...style, position: 'absolute', width: '100%' }}>
          {RenderSlide(Math.abs(idx))}
        </animated.div>
      ))}
      <Controllers
        next={() => setPosition(idx => Math.abs((idx + 1) % 3))}
        back={() =>
          setPosition(idx => {
            switch (idx) {
              case 2:
                return 1;

              case 1:
                return 0;

              case 0:
                return 2;

              default:
                return 0;
            }
          })
        }
        position={position}
      />
    </MainSection>
  );
};

const Controllers = ({ next, back, position }) => {
  const fadeInLeft = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-100px, 0)' },
  });

  return (
    <ControllerContainer style={fadeInLeft}>
      <ArrowContainer>
        <ArrowBox onClick={back}>
          <ArrowImg
            src={Math.abs(position) === 2 ? ArrowO : Arrow}
            style={{ transform: 'rotate(180deg)' }}
            alt="Left Arrow"
          />
        </ArrowBox>
        <ArrowBox onClick={next}>
          <ArrowImg src={Math.abs(position) === 2 ? ArrowO : Arrow} alt="Right Arrow" />
        </ArrowBox>
      </ArrowContainer>

      <SocialContainer>
        <HeightBar />

        <SocialIconBox>
          <div style={{ cursor: 'pointer' }}>
            <Icon
              icon="icon-Facebook"
              size="sm"
              onClick={() => window.open('https://www.facebook.com/jvcargo', '_blank')}
            />
          </div>
          <div style={{ cursor: 'pointer' }}>
            <Icon
              icon="icon-Instagram"
              size="sm"
              onClick={() => window.open('https://www.instagram.com/jvcargo', '_blank')}
            />
          </div>
          <div style={{ cursor: 'pointer' }}>
            <Icon
              icon="icon-Linkedin"
              size="sm"
              onClick={() =>
                window.open('https://www.linkedin.com/company/j-v-cargo-services-inc/?viewAsMember=true', '_blank')
              }
            />
          </div>
        </SocialIconBox>
      </SocialContainer>
    </ControllerContainer>
  );
};
