import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const Background = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(151deg, #fad6a6 0%, #ed672b 35%);
  min-height: 100vh;
  opacity: 1;
  color: white;
  width: 100%;
  min-height: 100vh;
  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: flex-start;
    min-height: 76.8rem;
  }
`;

export const Aireplane = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  margin: 0;
  display: flex;
  align-items: end;
  @media (min-width: 1280px) {
    margin-top: 100px;
    margin-left: 40px;
    height: 130px;
  }
`;

export const ImgMobile = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
  @media (min-width: 640px) {
    display: none;
  }
`;

export const ImgDesktop = styled.img`
  position: absolute;
  width: 329px;
  right: -20px;
  top: 45px;

  @media (min-width: 1280px) {
    width: auto;
    top: 15%;
    left: -20%;
  }
  @media (min-width: 768px) {
    width: 117%;
    right: -35px;
    top: 20%;
  }
`;

export const Headline = styled(animated.h1)`
  text-align: center;
  line-height: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 126px;

  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto 165px;
  }

  @media (max-width: 768px) {
    justify-content: end;
  }

  @media (min-width: 1280px) {
    flex: 0;
    left: 5%;
    width: 50%;
    text-align: start;
    width: 469px;
    margin-top: 200px;
    margin-bottom: 0;
  }
`;

export const Title = styled.p`
  font-size: 4rem;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0rem;
  font-family: 'Sofia Pro', sans-serif;
  text-shadow: 0px 3px 6px #00000029;

  @media (min-width: 1024px) {
    font-size: 6rem;
  }

  @media (min-width: 1280px) {
    text-align: left;
  }
`;

export const Subtitle = styled.p`
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  @media (min-width: 1280px) {
    font-size: 3rem;
  }
`;

export const ArrowContainer = styled(animated.div)`
  cursor: pointer;
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 1280px) {
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
  }
`;

export const BlurCircle = styled(animated.div)`
  width: 26rem;
  height: 26rem;
  margin: 0 auto 75px;
  border-radius: 50%;
  backdrop-filter: blur(13px);
  background: transparent linear-gradient(136deg, #fcfcfc66 0%, #ffffff0d 100%) 0% 0% no-repeat padding-box;

  @media (min-width: 768px) {
    margin: auto;
    width: 50rem;
    height: 50rem;
  }

  @media (min-width: 1024px) {
    width: 75rem;
    height: 75rem;
  }

  @media (min-width: 1280px) {
    width: 60rem;
    height: 60rem;
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  padding: 0 45.5px;
  flex-direction: column-reverse;
  margin-top: 100px;
  @media (min-width: 1280px) {
    margin-top: 0px;
    flex: 1;
    flex-direction: row;
    padding: 0 0 0 133px;
    justify-content: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: block;
    position: absolute;
    bottom: 40px;
    right: 85px;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
`;

export const Button = styled.button`
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff1a;
  font-size: 16px;
  font-weight: 600;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 2rem 2.7rem;
  border-radius: 5rem;
  color: white;
  cursor: pointer;
  height: 60px;
  min-width: 185px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.info};
  }
`;
