import React, { useEffect, useState } from 'react';
import { useSpring, useTrail, a } from 'react-spring';

import { Icon } from '../Icon';
import ServicioAereo from '../../images/servicio-aereo.svg';
import ServicioMaritimo from '../../images/Servicio Marítimo.svg';
import ServicioLocker from '../../images/Servicio Casillero.svg';
import ServicioStorage from '../../images/Servicio Bodegaje.svg';
import ServicioColUsa from '../../images/Servicio Col-USA.svg';
import ServicioAdd from '../../images/Servicios Adicionales.svg';
import CheckIcon from '../../images/CheckIcon.svg';

import PDF from '../../images/COL-USA_SERVICES.pdf';

import {
  ServicesContainer,
  TitleServices,
  ParagrahpServices,
  ServiceContent,
  BoxColor,
  ColumsInfo,
  BlurAndImage,
  ColumsContentBlur,
  ColumsImage,
  ColumsInfoAereo,
  AereoImageStyle,
  OptButtonContainer,
  Label,
  OptContent,
  OptsSection,
  BulletsContainer,
  CheckItem,
  CheckIconStyle,
  CheckText,
  CustomButton,
  BottomMessage,
  MessageContainer,
  TextContainer,
} from './styles';

const OPTS = [
  {
    icon: 'icon-aerial',
    label: 'Aéreo',
  },
  {
    icon: 'icon-maritime',
    label: 'Marítimo',
  },
  {
    icon: 'icon-locker',
    label: 'Casillero',
  },
  {
    icon: 'icon-storage',
    label: 'Bodegaje',
  },
  {
    icon: 'icon-col-usa',
    label: 'Col-Usa',
  },
  {
    icon: 'icon-aditional-service',
    label: 'Servicios Adicionales',
  },
];

export const Services = ({ handleRef, toContact, position }) => {
  const [selected, setSelected] = useState(0);

  const fadeInTitle = useSpring({
    opacity: position > 1350 ? 1 : 0,
    transform: position > 1350 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 0,
  });

  const fadeInDesc = useSpring({
    opacity: position > 1350 ? 1 : 0,
    transform: position > 1350 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 250,
  });

  const SERVICES = [
    {
      img: ServicioAereo,
      title: {
        label: 'Aéreo',
        size: 'md',
      },
      description: [
        'En la industria logística, nuestros clientes nos confían sus paquetes, encomiendas, menajes, equipajes no acompañados, porque somos líderes, con los mejores precios del mercado y la mejor atención.',
        'Contamos con aliados estratégicos que nos permiten asegurar tus envíos urgentes en su destino y en el menor tiempo.',
      ],
      order: [],
      bullets: [],
      button: {},
      bottomMessage: '',
    },
    {
      img: ServicioMaritimo,
      title: {
        label: 'Marítimo',
        size: 'md',
      },
      description: [
        'Contamos con las respectivas licencias nacionales e internacionales para realizar transporte marítimo de carga. Estas nos permiten brindarle un servicio variado de despachos y entrega puerto a puerto o puerta a puerta bajo el siguiente esquema:',
      ],
      order: ['Carga suelta.', 'Contenedores.', 'Consolidación de carga.'],
      bullets: [],
      button: {},
      bottomMessage: '',
    },
    {
      img: ServicioLocker,
      title: {
        label: 'Casillero',
        size: 'md',
      },
      description: [
        'J&V CARGO Services Inc le presta el servicio de envíos express por avión y le entregamos en la puerta de su casa, con nuestro servicio de casillero ahora todo va a ser más fácil. Este servicio te facilita la compra de productos a través del e-commerce generalmente en Estados Unidos.',
        'No esperes más y vive los beneficios de nuestro casillero virtual:',
      ],
      order: [],
      bullets: [
        {
          label: 'Seguridad',
          color: 'primary',
        },
        {
          label: 'Comodidad',
          color: 'primary',
        },
        {
          label: 'Economía',
          color: 'primary',
        },
        {
          label: 'Tiempo de entrega',
          color: 'primary',
        },
      ],
      button: {
        label: 'Solicitar Casillero',
        variant: 'primary',
        rightIcon: '',
        handleClick: toContact,
      },
      bottomMessage: '',
    },
    {
      img: ServicioStorage,
      title: {
        label: 'Bodegaje',
        size: 'md',
      },
      description: [
        'Contamos con una bodega muy bien ubicada, bajo los estándares infraestructurales apropiados y el personal profesional para ofrecer a nuestros clientes el servicio de recepción y almacenaje de su carga o sus mercancías.',
        'Esta cuenta con las condiciones necesarias para brindar mayor seguridad, manejo del producto en el espacio adecuado, y así evitar el deterioro de su carga.',
      ],
      order: [],
      bullets: [],
      button: {},
      bottomMessage: '',
    },
    {
      img: ServicioColUsa,
      title: {
        label: 'Col - USA',
        size: 'sm',
      },
      description: [
        '¡Empaca tus mejores recuerdos y nosotros los llevamos por ti! Haz tus envíos de encomienda desde Colombia a Estados Unidos, a los mejores precios y con la mejor atención! Los entregamos en la puerta de su destino.',
        'Nunca había sido tan fácil llevar un pedacito de ti a las personas que amas.',
        'Para más información, descarga el documento aquí enlazado o comunícate con uno de nuestros asesores.',
      ],
      order: [],
      bullets: [],
      button: {
        label: 'Descargar',
        variant: 'secondary',
        rightIcon: 'icon-arrow-down',
        handleClick: () => window.open(PDF, '_blank'),
      },
      bottomMessage: '',
    },
    {
      img: ServicioAdd,
      title: {
        label: 'Adicionales',
        size: 'md',
      },
      description: [
        '¡Tenemos más para ti! Acércate a nuestra oficina principal en Linden, New Jersey y enterate de los servicios adicionales que ofrecemos:',
      ],
      order: [],
      bullets: [
        {
          label: 'Envíos de dinero',
          color: 'warning',
        },
        {
          label: 'Envío de carga',
          color: 'warning',
        },
        {
          label: 'Pagos de factura',
          color: 'warning',
        },
        {
          label: 'Recargas internacionales',
          color: 'warning',
        },
        {
          label: 'Faxes',
          color: 'warning',
        },
        {
          label: 'Copias',
          color: 'warning',
        },
        {
          label: 'Envíos de documentos a cualquier parte del mundo.',
          color: 'warning',
        },
      ],
      button: {},
      bottomMessage: ['¡Somos agentes autorizados de DHL!', 'Consulta el valor del dólar en nuestras redes sociales'],
    },
  ];

  return (
    <ServicesContainer ref={handleRef}>
      <TitleServices style={fadeInTitle}>Servicios</TitleServices>
      <ParagrahpServices style={fadeInDesc}>
        En J&V CARGO Services Inc contamos con las herramientas adecuadas a nivel profesional, tecnológico y logístico
        para brindarte una mejor atención en todos nuestros servicios. ¡Pregúntanos!
      </ParagrahpServices>

      <ServiceContentComp {...SERVICES[selected]} selected={selected} position={position} />
      <OptsSection>
        <OptButton opts={OPTS} handleState={setSelected} position={position} />
      </OptsSection>
    </ServicesContainer>
  );
};

const OptButton = ({ opts, handleState, position }) => {
  const [width, setWidth] = useState(0);

  const trail = useTrail(opts.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: position > 1800 ? 1 : 0,
    y: position > 1800 ? 0 : -20,
    from: { opacity: 0, y: -20 },
    delay: 0,
  });

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return trail.map(({ opacity, ...animation }, idx) => (
    <OptContent key={idx} onClick={() => handleState(idx)}>
      <CircleButton
        style={{ ...animation, opacity }}
        opacity={opacity}
        icon={opts[idx].icon}
        label={opts[idx].label}
        width={width}
      />
    </OptContent>
  ));
};

const CircleButton = ({ style, icon, label, opacity, width }) => {
  const [{ transform }, set] = useSpring(() => ({ transform: 'scale(1)' }));

  return (
    <>
      <OptButtonContainer
        style={{ ...style, transform }}
        onMouseEnter={() => set({ transform: 'scale(1.2)' })}
        onMouseLeave={() => set({ transform: 'scale(1)' })}
      >
        <Icon icon={icon} size="md" />
      </OptButtonContainer>
      <Label style={{ opacity }}>{label}</Label>
    </>
  );
};

const ServiceContentComp = ({ title, img, description, bullets, order, selected, button, bottomMessage, position }) => {
  const fadeInComp = useSpring({
    opacity: position > 1450 ? 1 : 0,
    delay: 0,
  });

  const fadeIn = useSpring({
    opacity: position > 1450 ? 1 : 0,
    delay: 250,
    transform: position > 1450 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  return (
    <ServiceContent style={fadeInComp}>
      <BoxColor idx={selected} />
      <ColumsInfo>
        <BlurAndImage idx={selected}>
          <ColumsContentBlur></ColumsContentBlur>
          <ColumsImage>
            <AereoImageStyle style={fadeIn} src={img} alt="Imágen aérea" />
          </ColumsImage>
        </BlurAndImage>
        <ColumsInfoAereo sizeTitle={title.size} title={title.label}>
          <a.div style={fadeIn}>
            <h3>{title.label}</h3>
            <TextContainer idx={selected}>
              {description?.map((text, idx) => (
                <p key={idx}>{text}</p>
              ))}

              {!!order?.length && (
                <ol>
                  {order.map((opt, idx) => (
                    <li key={idx}>{opt}</li>
                  ))}
                </ol>
              )}
              {!!bullets?.length && (
                <BulletsContainer>
                  {bullets.map((bullet, idx) => (
                    <CheckItem key={idx}>
                      <CheckIconStyle src={CheckIcon} color={bullet.color} alt="Check icon" />
                      <CheckText>{bullet.label}</CheckText>
                    </CheckItem>
                  ))}
                </BulletsContainer>
              )}

              {!!bottomMessage.length && (
                <MessageContainer>
                  {bottomMessage.map((msg, idx) => (
                    <BottomMessage key={idx}>{msg}</BottomMessage>
                  ))}
                </MessageContainer>
              )}
            </TextContainer>
          </a.div>
        </ColumsInfoAereo>
      </ColumsInfo>
      {!!Object.keys(button).length && (
        <CustomButton variant={button.variant} onClick={button.handleClick}>
          {button.label}
          {!!button.rightIcon.length && <Icon size="xs" icon={button.rightIcon} />}
        </CustomButton>
      )}
    </ServiceContent>
  );
};
