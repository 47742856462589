import styled from '@emotion/styled';

export const HeaderWrapper = styled.header`
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: ${({ top }) => top + 'px'};
  width: 100vw;
  height: 9.6rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  color: ${({ theme }) => theme.colors.dark};
  border-bottom: 1px solid white;
  padding: 12px 36px;

  @media (min-width: 1280px) {
    height: ${({ top }) => (top >= 580 ? '96px' : '130px')};
    padding: 0 101px 0 96px;
  }
`;

export const BigLogo = styled.img`
  width: 85px;
  filter: drop-shadow(0px 3px 4px #00000029);
  @media (min-width: 1280px) {
    width: 110px;
  }
`;

export const BurgerContainer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MenuDesktop = styled.nav`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    gap: 74px;
  }
`;

export const NavLink = styled.button`
  font-size: 1.6rem;
  display: block;
  text-decoration: none;
  color: #1f3463;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scaleX(0);
    transition: transform 0.2s;
    transform-origin: left;
  }

  &:hover::before {
    transform: scaleX(1);
  }
`;

export const NavBurger = styled.div`
  display: flex;
  justify-content: end;
  padding: 32px 36px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const Nav = styled.nav`
  height: 100%;
  background: transparent linear-gradient(155deg, #ffffff66 0%, #ffffff0d 100%) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(17px);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  margin-top: 98px;
  margin-bottom: 85px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
`;

export const CustomButton = styled.button`
  background: transparent linear-gradient(180deg, #98989866 0%, #ffffff0d 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff1a;
  font-size: 16px;
  font-weight: 600;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 2rem 0;
  border-radius: 5rem;
  color: white;
  cursor: pointer;
  height: 60px;
  width: 185px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.info};
  }
`;
