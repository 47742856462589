import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Header } from '../shared/Header';
import { About } from '../components/About';
import { Services } from '../components/Services/';
import { Cobertura } from '../components/Cobertura';
import { Contact } from '../components/Contact';
import { Carousel } from '../components/Carousel';
import { isBrowser } from '../utils';

const scrollToRef = ref => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

// markup
const IndexPage = () => {
  const refHeader = useRef(null);
  const refAbout = useRef(null);
  const refServices = useRef(null);
  const refCoverage = useRef(null);
  const refContact = useRef(null);

  const MENU_OPTS = [
    {
      label: 'Inicio',
      scroll: () => scrollToRef(refHeader),
    },
    {
      label: 'Nosotros',
      scroll: () => scrollToRef(refAbout),
    },
    {
      label: 'Servicios',
      scroll: () => scrollToRef(refServices),
    },
    {
      label: 'Cobertura',
      scroll: () => scrollToRef(refCoverage),
    },
    {
      label: 'Contacto',
      scroll: () => scrollToRef(refContact),
    },
  ];

  const [position, setPosition] = useState(0);

  const handleNavigation = useCallback(e => {
    const window = e.currentTarget;
    if (window.scrollY === 0) {
      // setSticky(false);
    } else if (window.scrollY >= 1) {
      // setSticky(true);
    }
    setPosition(window.scrollY);
  }, []);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    setPosition(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="oebgnvj0358migw4ex1i3ikkgg22ah" />
        <title>J&amp;V Cargo Services inc.</title>
      </Helmet>
      <Header menu={MENU_OPTS} />
      <Carousel handleRef={refHeader} arrowFunc={() => scrollToRef(refAbout)} position={position} />
      <About handleRef={refAbout} position={position} />
      <Services handleRef={refServices} toContact={() => scrollToRef(refContact)} position={position} />
      <Cobertura handleRef={refCoverage} position={position} />
      <Contact handleRef={refContact} position={position} />
      {/* {allWpPage.edges.map(({ page }) => (
        <PStyle key={page.id}>{page.title}</PStyle>
      ))} */}
    </>
  );
};

export default IndexPage;
