import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { send } from 'emailjs-com';
import { useSpring } from 'react-spring';

import {
  Area,
  BellImg,
  Container,
  Description,
  LeftPanel,
  Form,
  FormController,
  Footer,
  Headline,
  Input,
  Label,
  RightPanel,
  SendBtn,
  Title,
  TitleContainer,
  FooterContainer,
  FooterTitle,
  Col,
  FooterText,
  Legal,
  WomanImg,
  SocialNetworks,
  SocialTitle,
  SocialIconBox,
  EmailError,
  Maps,
} from './styles';

import { Icon } from '../Icon';
import NotificationBell from '../../images/notification.png';
import Woman from '../../images/woman.png';
import { EMAIL_KEYS } from '../../emailkey';

export const Contact = ({ handleRef, position }) => {
  const [loading, setLoading] = useState(false);

  const fadeInTitle = useSpring({
    opacity: position > 3200 ? 1 : 0,
    transform: position > 3200 ? 'translate(0, 0)' : 'translate(0, -50px)',
  });

  const fadeInDesc = useSpring({
    opacity: position > 3200 ? 1 : 0,
    transform: position > 3200 ? 'translate(0, 0)' : 'translate(0, -50px)',
    delay: 250,
  });

  const fadeInBell = useSpring({
    opacity: position > 3200 ? 1 : 0,
    transform: position > 3200 ? 'translate(0, 0)' : 'translate(0, -50px)',
    delay: 150,
  });

  const fadeInEmailField = useSpring({
    opacity: position > 3400 ? 1 : 0,
    transform: position > 3400 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 350,
  });

  const fadeInNameField = useSpring({
    opacity: position > 3400 ? 1 : 0,
    transform: position > 3400 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 450,
  });

  const fadeInTelField = useSpring({
    opacity: position > 3400 ? 1 : 0,
    transform: position > 3400 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 550,
  });

  const fadeInContentField = useSpring({
    opacity: position > 3400 ? 1 : 0,
    transform: position > 3400 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 650,
  });

  const fadeInButtonField = useSpring({
    opacity: position > 3500 ? 1 : 0,
    transform: position > 3500 ? 'translate(0, 0)' : 'translate(-50px, 0)',
    delay: 750,
  });

  const fadeInSocialMedia = useSpring({
    opacity: position > 3600 ? 1 : 0,
    delay: 850,
    transform: position > 3600 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const fadeInMap = useSpring({
    opacity: position > 3600 ? 1 : 0,
    delay: 200,
    transform: position > 3600 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const fadeInFooter = useSpring({
    opacity: position > 3600 ? 1 : 0,
    delay: 300,
    transform: position > 3600 ? 'scale(1)' : 'scale(0.6)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  const { handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      email: '',
      name: '',
      tel: '',
      content: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('El campo correo electrónico no es valido')
        .required('El campo correo electrónico es obligatorio'),
      name: Yup.string().required('El campo nombre es obligatorio'),
      tel: Yup.number('El teléfono debe ser numérico').required('El campo teléfono es obligatorio'),
      content: Yup.string().required('Debes escribir algo en el correo'),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        await send(EMAIL_KEYS.SERVICE_ID, EMAIL_KEYS.TEMPLATE_ID, values, EMAIL_KEYS.USER_ID);

        showSuccess('¡Mensaje enviado con éxito!');
        setLoading(false);
      } catch (error) {
        showError('Uups! algo salio mal');
        setLoading(false);
      }
    },
  });

  const showSuccess = msg =>
    toast.success(msg, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const showError = msg =>
    toast.error(msg, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <Container ref={handleRef}>
      <LeftPanel>
        <TitleContainer>
          <Headline>
            <Title style={fadeInTitle}>Contacto</Title>
            <Description style={fadeInDesc}>
              Diligencia este formulario para preguntas e inquietudes sobre nuestros servicios y también para solicitar
              el servicio de casillero. ¡Escríbenos!
            </Description>
          </Headline>
          <BellImg style={fadeInBell} src={NotificationBell} alt="Notification Bell" />
        </TitleContainer>
        <Form onSubmit={handleSubmit}>
          <FormController style={fadeInEmailField}>
            <Label htmlFor="email">Email</Label>
            <Input type="email" id="email" name="email" onChange={handleChange} value={values.email} />
            {errors?.email && <EmailError>{errors.email}</EmailError>}
          </FormController>
          <FormController style={fadeInNameField}>
            <Label htmlFor="name">Nombre</Label>
            <Input type="text" id="name" name="name" onChange={handleChange} value={values.name} />
            {errors?.name}
          </FormController>
          <FormController style={{ width: '21rem', ...fadeInTelField }}>
            <Label htmlFor="tel">Teléfono</Label>
            <Input type="tel" id="tel" name="tel" onChange={handleChange} value={values.tel} />
          </FormController>
          <FormController style={fadeInContentField}>
            <Label htmlFor="content">¿Cómo podemos ayudarte?</Label>
            <Area cols="40" rows="5" id="content" name="content" onChange={handleChange} value={values.content} />
          </FormController>

          <FormController style={fadeInButtonField}>
            <SendBtn type="submit" disabled={loading}>
              Enviar
            </SendBtn>
          </FormController>
        </Form>

        <SocialNetworks style={fadeInSocialMedia}>
          <SocialTitle>Conéctate con nosotros</SocialTitle>
          <SocialIconBox>
            <div style={{ cursor: 'pointer' }}>
              <Icon icon="icon-Facebook" onClick={() => window.open('https://www.facebook.com/jvcargo', '_blank')} />
            </div>
            <div style={{ cursor: 'pointer' }}>
              <Icon icon="icon-Instagram" onClick={() => window.open('https://www.instagram.com/jvcargo', '_blank')} />
            </div>
            <div style={{ cursor: 'pointer' }}>
              <Icon
                icon="icon-Linkedin"
                onClick={() =>
                  window.open('https://www.linkedin.com/company/j-v-cargo-services-inc/?viewAsMember=true', '_blank')
                }
              />
            </div>
          </SocialIconBox>
        </SocialNetworks>
        <WomanImg style={fadeInSocialMedia} src={Woman} alt="Woman with a laptop" />
      </LeftPanel>
      <RightPanel>
        <Maps
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.0884847042666!2d-74.22870188517828!3d40.64997984934541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b31c664e9e5f%3A0x323a1e1db1438e2e!2sJ%20%26%20V%20CARGO%20Services%20Inc!5e0!3m2!1ses!2sco!4v1631662958013!5m2!1ses!2sco"
          title="JVCargo - map"
          style={{ border: 0, ...fadeInMap }}
          allowFullScreen=""
          loading="lazy"
        />
        <Footer style={fadeInFooter}>
          <FooterContainer>
            <Col style={{ flex: '0 0 50%' }}>
              <FooterTitle>Dirección</FooterTitle>
              <FooterText>2430 E. Linden Ave, Linden</FooterText>
              <FooterText>NJ 07036</FooterText>
              <FooterText>USA.</FooterText>
              <FooterText>+1 908 8620101</FooterText>
              <FooterText>+1 908 9065626</FooterText>
            </Col>
            <Col>
              <FooterTitle>Servicio al cliente</FooterTitle>
              <FooterText>Contáctanos aquí. info@jvcargo.com</FooterText>
              <FooterText variant>Lun. a Vie. 8am - 7pm</FooterText>
              <FooterText variant>Sab. 9am - 7pm</FooterText>
              <FooterText variant>Dom. 10am - 4pm</FooterText>
            </Col>
          </FooterContainer>
          <Legal>Copyright ©2021 J&amp;V CARGO Services Inc. All rights reserved.</Legal>
        </Footer>
      </RightPanel>

      <ToastContainer />
    </Container>
  );
};
