import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Icon } from '../../components/Icon';
import {
  BigLogo,
  HeaderWrapper,
  MenuDesktop,
  BurgerContainer,
  NavLink,
  Nav,
  List,
  NavBurger,
  ButtonContainer,
  CustomButton,
} from './styles';

import LogoBg from '../../images/logo-bg.png';
import LogoSm from '../../images/logo-sm.png';
import { isBrowser } from '../../utils';

export const Header = ({ menu }) => {
  const container = useRef(null);

  const [sticky, setSticky] = useState(false);
  const [open, setOpen] = useState(false);
  const [y, setY] = useState(0);

  const handleNavigation = useCallback(e => {
    const window = e.currentTarget;
    if (window.scrollY === 0) {
      setSticky(false);
    } else if (window.scrollY >= 1) {
      setSticky(true);
    }
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.text = 'text/javascript';
    script.src = 'https://app.bluecaribu.com/conversion/integration/5bcc51a7446db8912a5fde9e5871b375';
    container.current.appendChild(script);
  }, []);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <>
      <HeaderWrapper ref={container} sticky={sticky} top={y}>
        <BigLogo src={y >= 580 ? LogoSm : LogoBg} alt="Big Logo" />

        <BurgerContainer>
          <Icon size="md" icon="icon-menu" onClick={() => setOpen(true)} />
        </BurgerContainer>
        <MenuDesktop>
          {menu.map(({ label, scroll }, idx) => (
            <NavLink key={idx} onClick={scroll}>
              {label}
            </NavLink>
          ))}
        </MenuDesktop>
      </HeaderWrapper>
      <Drawer open={open} handleOpen={setOpen} menu={menu} />
    </>
  );
};

const Drawer = ({ menu, handleOpen, open }) => {
  if (!open) return null;

  return (
    <Nav>
      <NavBurger>
        <Icon size="md" icon="icon-cross" onClick={() => handleOpen(false)} />
      </NavBurger>
      <List>
        {menu.map(({ label, scroll }, idx) => (
          <li key={idx}>
            <NavLink onClick={scroll}>{label}</NavLink>
          </li>
        ))}
      </List>

      <ButtonContainer>
        <CustomButton onClick={() => window.open('http://jvcargo.controlbox.net/app/rastreo/', '_blank')}>
          Rastreo de carga
        </CustomButton>
        <CustomButton onClick={() => window.open('http://jvcargo.controlbox.net/app/', '_blank')}>
          Ingreso agencias
        </CustomButton>
      </ButtonContainer>
    </Nav>
  );
};
