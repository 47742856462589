import styled from '@emotion/styled';
import { a } from 'react-spring';

export const Background = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #4fbec8;
  min-height: 100vh;
  opacity: 1;
  color: white;
  width: 100%;
  @media (min-width: 1280px) {
    padding: 0 101px 0 96px;
    flex-direction: row-reverse;
    min-height: 76.8rem;
    justify-content: space-between;
    gap: 5%;
  }
`;

export const ImgContainer = styled(a.div)``;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  margin-top: 85px;
  gap: 10%;
  @media (min-width: 1280px) {
    margin-top: 100px;
    height: auto;
  }
`;

export const TextContainer = styled.div`
  padding: 0 38px;
  margin-top: -25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    position: relative;
    margin-top: 200px;
    align-items: flex-start;
  }
`;

export const Title = styled(a.h1)`
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: 'Sofia Pro', sans-serif;
  line-height: 1;
  width: 242px;
  margin: auto;
  text-shadow: 0px 3px 6px #00000029;

  @media (min-width: 1280px) {
    margin: 0 auto;
    font-size: 70px;
    text-align: start;
    width: 425px;
  }
`;

export const Description = styled(a.p)`
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
  width: 300px;

  @media (min-width: 1280px) {
    margin: 1rem 0;
    text-align: start;
    width: 365px;
  }
`;

const ButtonColor = variant =>
  ({
    primary: ({ theme }) => theme.colors.primary,
    secondary: ({ theme }) => theme.colors.dark,
  }[variant ?? 'primary']);

const ButtonSize = size =>
  ({
    sm: '0.4rem 1.4rem',
    md: '1.2rem 2.4rem',
  }[size ?? 'md']);

export const CustomButton = styled(a.button)`
  padding: ${({ size }) => ButtonSize(size)};
  border: none;
  background-color: ${({ variant }) => ButtonColor(variant)};
  color: white;
  border-radius: 20rem;
  margin: auto;

  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }

  @media (min-width: 1280px) {
    margin: 0;
    min-width: 152px;
  }
`;

export const ButtonContainer = styled(a.div)`
  display: flex;
  flex-direction: column;
  margin: 0.65rem auto 1.25rem;

  @media (min-width: 1280px) {
    position: absolute;
    bottom: 60px;
    left: 210px;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;
