import React, { useState } from 'react';
import { useSpring } from 'react-spring';

import {
  Background,
  Description,
  Img,
  ImgContainer,
  TextContainer,
  Title,
  CustomButton,
  ButtonContainer,
} from './styles';

import { Icon } from '../Icon';
import Rollo from '../../images/rollo.png';
import DocIcon from '../../images/DocIcon.svg';
import FIRSTPDF from '../../images/0046.pdf';
import SECONDPDF from '../../images/1165.pdf';
import TERTYPDF from '../../images/2142.pdf';
import { Button, ButtonContainer as HiddenButton } from '../Slogan/styles';

export const OurInfo = ({ handleRef }) => {
  const [state, toggle] = useState(false);

  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 150 },
  });

  const fadeInLeftOne = useSpring({
    opacity: 1,
    delay: 500,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeInLeftTwo = useSpring({
    opacity: 1,
    delay: 600,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeInLeftThree = useSpring({
    opacity: 1,
    delay: 700,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(-50px, 0)' },
  });

  const fadeIn = useSpring({
    opacity: 1,
    delay: 250,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.6)' },
  });

  return (
    <Background ref={handleRef}>
      <ImgContainer style={fadeIn}>
        <Img src={Rollo} alt="Rollo" />
      </ImgContainer>

      <TextContainer>
        <Title style={fadeInLeftOne}>Nuestra información a tu alcance</Title>
        <Description style={fadeInLeftTwo}>
          Infórmate sobre las normas, condiciones y restricciones a la hora de utilizar nuestros servicios en las
          oficinas de J&amp;V CARGO Services Inc. ¡Descárgalos ya!
        </Description>

        <CustomButton onClick={() => toggle(!state)} style={{ width: '152px', cursor: 'pointer', ...fadeInLeftThree }}>
          Descargar <Icon size="xs" icon="icon-arrow-down" />
        </CustomButton>

        <ButtonContainer
          style={{
            opacity: x.to({ range: [0, 1], output: [0, 1] }),
            cursor: x ? 'pointer' : 'default',
          }}
        >
          <CustomButton size="sm" onClick={() => window.open(FIRSTPDF, '_blank')} disabled={!state}>
            <img src={DocIcon} alt="doc icon" />
            Resolución 0046 de 2019
          </CustomButton>
          <CustomButton size="sm" onClick={() => window.open(SECONDPDF, '_blank')} disabled={!state}>
            <img src={DocIcon} alt="doc icon" />
            Decreto 1165 de 2019
          </CustomButton>
          <CustomButton size="sm" onClick={() => window.open(TERTYPDF, '_blank')} disabled={!state}>
            <img src={DocIcon} alt="doc icon" />
            Decreto 2142 de 2016
          </CustomButton>
        </ButtonContainer>
      </TextContainer>

      <HiddenButton>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/rastreo/', '_blank')}>
          Rastreo de carga
        </Button>
        <Button onClick={() => window.open('http://jvcargo.controlbox.net/app/', '_blank')}>Ingreso agencias</Button>
      </HiddenButton>
    </Background>
  );
};
