import React from 'react';

import Sprites from '../../icons/sprites.svg';
import { SvgWrapper } from './styles';

export const Icon = ({ size, icon, onClick }) => {
  return (
    <SvgWrapper size={size} onClick={onClick}>
      <use xlinkHref={`${Sprites}#${icon}`} />
    </SvgWrapper>
  );
};
