import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const MainContainer = styled.article`
  width: 100%;
  @media (min-width: 1280px) {
  }
`;

export const CenterMainContainer = styled.article`
  margin: 0 auto;
  padding: 56px 10%;
  @media (min-width: 1280px) {
    padding: 95px 101px;
  }
`;

export const Section = styled.section`
  color: #1f3463;
  width: 100%;
  min-height: 56rem;
`;

export const TwoColums = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 auto;
  justify-content: space-between;
  gap: 0px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1280px) {
    gap: 80px;
  }
`;

export const ColumAbout = styled.div`
  width: 100%;
  flex: 0 0 50%;
  @media (min-width: 992px) {
    width: 50%;
  }
`;

export const ColumImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex: 0 0 50%;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    flex: 1;
    height: 500px;
  }
`;

export const Title = styled(animated.h2)`
  text-align: left;
  letter-spacing: 0px;
  font-size: 4rem;
  line-height: 1;
  font-weight: bold;
  font-family: 'Sofia Pro', sans-serif;
  margin-bottom: 1.8rem;
  @media (min-width: 1280px) {
    font-size: 8rem;
  }
`;

export const PStyle = styled(animated.p)`
  font-size: 1.4rem;
  letter-spacing: 0.42px;
  flex: 1;
  width: 267px;
  @media (min-width: 1280px) {
    width: 375px;
  }
`;

export const UlStyle = styled.ul`
  list-style: none;
  margin-top: 5rem;
  @media (min-width: 1280px) {
  }
`;

export const ListItemStyle = styled(animated.li)`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  width: 295px;
  @media (min-width: 1280px) {
    width: 410px;
    margin-left: 3.1rem;
  }
`;

export const Bullet = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #ed672b;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.2rem;

  @media (min-width: 1280px) {
    margin-right: 3.1rem;
  }
`;

export const ImgContainer = styled(animated.div)`
  position: relative;
  margin-top: 1rem;
  height: 100%;
  width: 100%;

  @media (min-width: 768px) {
    margin-right: 1rem;
  }

  @media (min-width: 992px) {
    margin-right: 0;
    height: 100%;
    width: 100%;
  }
`;

export const ImgStyle = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 121%;
    top: 0;
    position: absolute;
    right: -25%;
  }

  @media (min-width: 992px) {
    width: 100%;
  }

  @media (min-width: 1280px) {
    right: 15%;
  }
`;

export const Footer = styled(animated.div)`
  background-color: #4fbec8;
  padding: 2.4rem 3.6rem;
  @media (min-width: 992px) {
    padding: 4rem 10.1rem;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    justify-content: space-between;
  }
`;

export const LogosContainer = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subtitle = styled(Title)`
  color: white;
  font-size: 4rem;
  margin-bottom: 1.5rem !important;
  @media (min-width: 992px) {
    font-size: 6rem;
    margin-bottom: 0rem;
  }
`;

export const Logos = styled.img`
  height: 4rem;
  margin: 0rem 2.5rem;
  @media (min-width: 992px) {
    height: 7.5rem;
  }
`;
